import React, {useState, useEffect} from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';

const PortfolioNav = ({items}) => {
  
  const [sticky, setSticky] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    };
  });
  
  const handleScroll = () => {
    var mainBounding = document.querySelector('#homeMain').getBoundingClientRect();

    if (mainBounding.top < 0) {
        if(!sticky){
          setSticky(true);
        }
    } else {
        if(sticky){
          setSticky(false);
        }
    }

    for(let i = items.length; i > 0 ; i--) {
      checkBounding(items[i-1], i-1);
    }
  }
  
  const checkBounding =  (item, index) => {
    let queryItem = "#" + item;
    let boundingInfo = document.querySelector(queryItem).getBoundingClientRect();

    if (boundingInfo.top < 80 && boundingInfo.bottom > 0) {
      if (highlightedItem !== index){
        setHighlightedItem(index);
      }
    }
  }
  
  return (
    <div className={sticky ? "portfolio-nav fixed" : "portfolio-nav"}>
        <ul>
            {
              items.map((item, index) => {
                let itemID = "#"+item;
                return(
                  <li onClick={() => scrollTo(itemID)} className={(index === highlightedItem) ?"selected" : null}>{item}</li>
                );
              })
            }
        </ul>
    </div>
  )
}

export default PortfolioNav;