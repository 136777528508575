import React from "react"
import {useStaticQuery, graphql} from "gatsby"

import "../styles/homeMain.scss"

import PortfolioMainItem from "./portfolioMainItem"
import PortfolioExtraItem from "./portfolioExtraItem"
import PortfolioNav from "./portfolioNav"

const HomeMain = props => {
    const portfolioData = useStaticQuery(graphql`
        query portfolioQuery {
            allSanityPortfolioMainItem(sort: {fields: placement, order: ASC}) {
                edges {
                    node {
                        name
                        excerpt
                        tags
                        type
                        id
                        slug {
                            _key
                            _type
                            current
                        }
                        bannerImage {
                            asset {
                              fluid {
                                ...GatsbySanityImageFluid
                              }
                            }
                        }
                    }
                }
            }
            allSanityPortfolioExtraItem(sort: {fields: placement, order: ASC}) {
                edges {
                    node {
                        name
                        id
                        image {
                            asset {
                                fluid {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                        slug {
                            _key
                            _type
                            current
                        }
                    }
                }
            }
        }       
    `)
    
    const portfolioMainArray = portfolioData.allSanityPortfolioMainItem.edges;
    const portfolioMainNames = portfolioMainArray.map(edge => edge.node.name);
    //console.log(portfolioMainNames);
    const portfolioExtraArray = portfolioData.allSanityPortfolioExtraItem.edges;
    
  return (
    <main className="homeMain" id="homeMain">
        <div className="container">
            <div className="portfolio-main">
                <PortfolioNav items={portfolioMainNames}/>
                <div className="portfolio-main-items">
                    {
                        portfolioMainArray.map(edge => 
                            (
                                <PortfolioMainItem
                                    key={edge.node.id} 
                                    name={edge.node.name}
                                    description={edge.node.excerpt}
                                    tags={edge.node.tags}
                                    type={edge.node.type}
                                    image={edge.node.bannerImage.asset.fluid}
                                    slug={edge.node.slug.current}
                                />
                            )
                        )
                    }
                </div>
            </div>
            <div className="portfolio-extra">
                <div className="portfolio-extra-header">
                    <h2>Extras</h2>
                    <p>Web Development, Graphic Design & More</p>
                </div>
                <div className="four-grid">
                    {
                        portfolioExtraArray.map(edge => 
                            (
                                <PortfolioExtraItem
                                    slug={edge.node.slug.current} 
                                    image={edge.node.image.asset.fluid}
                                    key={edge.node.id} 
                                />
                            )
                        )
                    }
                </div>
            </div>
        </div>
    </main>
  )
}

export default HomeMain
