import React from "react"
import {Link} from "gatsby"
import Img from "gatsby-image"

const portfolioExtraItem = (props) => {
  return (
    <Link to={"/" + props.slug}>
      <div className="portfolioExtraItem">
          <Img fluid={props.image}/>
      </div>
    </Link>
  )
}

export default portfolioExtraItem;