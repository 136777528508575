import React from "react"
import "../styles/hero.scss"
import Container from "./container"
import TextLoop from "react-text-loop";

const Hero = () => {

  return (
    <div className="hero" id="hero">
        <Container>
            <div className="tagline">
                {/*<h1>Sparking delight in users through</h1>*/}
                <TextLoop 
                  interval={2500} 
                  springConfig={{ stiffness: 220, damping: 18 }} 
                  className="changingTag"
                  noWrap={false}>
                    <h1>Sparking delight in users through</h1>
                    <h1>Synthesizing data to create</h1>
                    <h1>Prototyping with code to test</h1>
                    <h1>Referencing game design to inspire</h1>
                </TextLoop>
                <h1>fluent experiences rooted in human-centered design</h1>
            </div>
            <p>I'm an interaction designer with a passion for development and information architecture.</p>
        </Container>
    </div>
  )
}

export default Hero
